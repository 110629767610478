.bock-overlay-close {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  width: 100%;
  height: 58px;
  background: url(bg-close@2x.png) center no-repeat #41444e;
  background-size: 48px 48px;
  cursor: pointer;
  z-index: 8040;
}
.bkoverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  z-index: 102220;
  background: #efeeee;
  text-align: center;
  overflow: auto;
}

.bkoverlay iframe {
  border : 0;
}
