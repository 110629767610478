.searchForm {
  padding: 15px 0;
}

.searchForm :global(.ais-SearchBox) {
  position: relative;
  max-width: 100%;
}

.searchForm :global(.ais-SearchBox-form) {
  position: relative;
  margin: 0;
}

.searchForm :global(.ais-SearchBox-input) {
  width: 100%;
  height: 45px;
  padding: 0 45px 0 20px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 25px;
  font-size: 14px;
  outline: none;
  box-shadow: none;
}

.searchForm :global(.ais-SearchBox-submit) {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(-70%, -60%);
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: 45px;
  display: flex;
  align-items: center;
}

.searchForm :global(.ais-SearchBox-submit i) {
  font-size: 18px;
  color: #41444e;
  display: block;
}

.searchForm :global(.ais-SearchBox-reset) {
  display: none;
}

.searchForm :global(.ais-SearchBox-input:focus) {
  border-color: #ddd;
}
